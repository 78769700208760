import {DEFAULT_MAX_TEXT_TRUNCATE_CHAR_NUMBER, ENCODING_CHARACTERS} from 'consts.json'

/**
 * Returns boolean value from strings "true" and "false", otherwise null
 * @param string
 */
export const stringToBooleanOrNull = string => {
  switch (string) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return null
  }
}

/**
 * Create random string using crypto API
 */
export const generateRandomString = (length: number): string => {
  const arr = new Uint8Array(length)
  crypto.getRandomValues(arr)
  const mapped = []
  arr.forEach(el => {
    mapped.push(ENCODING_CHARACTERS.charAt(el % ENCODING_CHARACTERS.length))
  })
  return mapped.join('')
}

/**
 * Check does string begins with the given string
 *
 * @param {String} haystack String which need to test
 * @param {String} needle String to search at the beginning
 * @param {Boolean} lowercase Ignore letter case.
 * @returns {Boolean}  Returns processed value.
 */
export function stringStartsWith(haystack, needle, lowercase = true) {
  if (haystack.length === '' || needle.length === 0) return false
  if (lowercase) return haystack.toLowerCase().startsWith(needle.toLowerCase())
  return haystack.startsWith(needle)
}

/**
 * Truncate text to max number of characters.
 *
 * @param {String} text Text which should be truncated.
 * @param {Integer} max Max number of characters.
 */
export const truncateText = (text, max = DEFAULT_MAX_TEXT_TRUNCATE_CHAR_NUMBER) => {
  return text.substr(0, max) + (text.length > max ? '...' : '')
}

/**
 * Return reduced place description length
 *
 * @param {String} content which should be reduced .
 * @param {Integer} maxLength max length of content .
 */
export const getShortenedText = (content, maxLength) => {
  if (content.length > maxLength) {
    let shorterContent = content.substr(0, maxLength)
    let ellipsis = '...'
    let cutIndex = shorterContent.lastIndexOf(' ')
    let lastChar = shorterContent.charAt(cutIndex - 1)

    if (lastChar.match(/[,:;-]/)) {
      cutIndex -= 1
    } else if (lastChar.match(/[.!?]/)) {
      ellipsis = ' ' + ellipsis
    }

    shorterContent = shorterContent.substring(0, cutIndex) + ellipsis

    return shorterContent
  } else {
    return content
  }
}

/**
 * Prepends string with multiple "x", for example for showing bank account numbers
 *
 * @param {String} last4Digits
 * @return {string}
 */
export const prependXxxxToLast4Digits = last4Digits => {
  return 'xxxx xxxx xxxx ' + last4Digits
}

export const removeLineBreaks = string => {
  return string.replace(/\r?\n|\r/g, '')
}
