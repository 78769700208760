import {isNumeric} from 'common/helpers/validation'
import {CURRENCY_SYMBOLS} from 'consts.json'

import invariant from './invariant'

/**
 * Resolve currency symbol based on string.
 * @param {String} currency Currency string.
 */
export function getCurrencySymbol(currency) {
  return CURRENCY_SYMBOLS[currency?.toUpperCase()] || currency
}

export const formatAmmount = price => {
  return isNumeric(price) ? price : Number(parseFloat(price))
}

export const formatAmmount2 = price => {
  if (typeof price === 'string' && isStringDecimalNumber(price)) {
    const parts = price.split('.')
    if (parts.length > 1) {
      // TODO: Handle rounding.
      return `${parts[0]}.${parts[1].substring(0, 2)}`
    }
    return `${parts[0]}.00`
  }

  return formatAmmount(price).toFixed(2)
}

export const formatMoney = (currency, amount, separator = '') => {
  if (!currency || !(isNumeric(amount) || isStringDecimalNumber(amount))) {
    return null
  }

  const symbol = getCurrencySymbol(currency)
  const amount2dp = formatAmmount2(amount)

  return `${symbol}${separator}${amount2dp}`
}

export const formatPrice = formatMoney

export const roundAmountToDigits = (amount, digits) => {
  if (!isStringDecimalNumber(amount)) {
    return null
  }

  return formatAmmount(amount).toFixed(digits)
}

export const isStringDecimalNumberTest = maxNumberOfDecimals => {
  const rgx =
    typeof maxNumberOfDecimals === 'number'
      ? new RegExp(`^\\d*(\\.?\\d{0,${maxNumberOfDecimals}})?$`)
      : new RegExp('^\\d*(\\.?\\d*)?$')
  return value => value.match(rgx)
}

export const isStringDecimalNumber = value => !!isStringDecimalNumberTest()(value)

export const compareStringDecimals = (a, b) => {
  invariant(typeof a === 'string')
  invariant(typeof b === 'string')

  if (a.indexOf('.') == -1) {
    a += '.0'
  }
  if (b.indexOf('.') == -1) {
    b += '.0'
  }
  let [aIntegerPart, aDecimalPart] = a.split('.')
  let [bIntegerPart, bDecimalPart] = b.split('.')
  if (aIntegerPart.length < bIntegerPart.length) {
    aIntegerPart = aIntegerPart.padStart(bIntegerPart.length, '0')
  } else if (bIntegerPart.length < aIntegerPart.length) {
    bIntegerPart = bIntegerPart.padStart(aIntegerPart.length, '0')
  }
  if (aDecimalPart.length < bDecimalPart.length) {
    aDecimalPart = aDecimalPart.padEnd(bDecimalPart.length, '0')
  } else if (bDecimalPart.length < aDecimalPart.length) {
    bDecimalPart = bDecimalPart.padEnd(aDecimalPart.length, '0')
  }

  const aFormatted = aIntegerPart + aDecimalPart
  const bFormatted = bIntegerPart + bDecimalPart

  // Now, if we had '13451' and '23.21' as input, he have them formatted as '1345100' and '0002321'.
  // And now, we can just compare string lexicografically.
  return aFormatted.localeCompare(bFormatted)
}

export const maxStringDecimalTest = maxValue => value => compareStringDecimals(maxValue, value) >= 0
export const minStringDecimalTest = minValue => value => compareStringDecimals(minValue, value) <= 0

export const isCurrencySymbol = value => Object.values(CURRENCY_SYMBOLS).includes(value)
export const stripCurrencySymbol = value =>
  value.length > 0 && isCurrencySymbol(value[0]) ? value.substring(1) : value
