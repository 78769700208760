import config from 'config'
import {apiCall, createApiInstance, getHeader} from './cpApi'

export const CPBackendApi = createApiInstance(config.API_SERVER_BASE + '/api')

export const putAsync = async (url, data, token = '', lang = 'en', additionalHeaders) => {
  return await CPBackendApi.put(url, data, getHeader(token, lang, additionalHeaders))
}

export const put = (url, data, onSuccess = () => {}, onError = () => {}) => {
  apiCall(() => putAsync(url, data), onSuccess, onError)
}

export const postAsync = async (url, data, token = '', lang = 'en', additionalHeaders) => {
  return await CPBackendApi.post(url, data, getHeader(token, lang, additionalHeaders))
}

export const post = (url, data, onSuccess = () => {}, onError = () => {}) => {
  apiCall(() => postAsync(url, data), onSuccess, onError)
}

export const postWithOptions = (url, data, options) => {
  const token = (options && options.token) || ''
  const lang = (options && options.lang) || 'en'
  const additionalHeaders = (options && options.additionalHeaders) || {}
  const onSuccess = (options && options.onSuccess) || null
  const onError = (options && options.onError) || null

  apiCall(() => postAsync(url, data, token, lang, additionalHeaders), onSuccess, onError)
}

export const putWithOptions = (url, data, options) => {
  const token = (options && options.token) || ''
  const lang = (options && options.lang) || 'en'
  const additionalHeaders = (options && options.additionalHeaders) || {}
  const onSuccess = (options && options.onSuccess) || null
  const onError = (options && options.onError) || null

  apiCall(() => putAsync(url, data, token, lang, additionalHeaders), onSuccess, onError)
}

export const deleteAsync = async (url, additionalHeaders = {}) => {
  return await CPBackendApi.delete(url, getHeader('', 'en', additionalHeaders))
}

export const deleteReq = (url, onSuccess, onError) => {
  apiCall(() => deleteAsync(url), onSuccess, onError)
}

export const getAsync = async (url, token = '', lang = 'en', additionalHeaders = {}) => {
  return await CPBackendApi.get(url, getHeader(token, lang, additionalHeaders))
}

export const get = (url, token, onSuccess = () => {}, onError = () => {}, lang = 'en') => {
  apiCall(() => getAsync(url, token, lang), onSuccess, onError)
}
