import {TIME_DATE} from 'consts.json'

const YEAR_STRING_MIN_LENGTH = 4

export function displayDurationInHours(minutes) {
  const MINUTES_IN_HOUR = 60
  let duration = ''

  if (minutes < MINUTES_IN_HOUR) {
    duration = `${minutes}min`
  } else {
    const hours = minutes / MINUTES_IN_HOUR
    const rhours = Math.floor(hours)
    const minutesRest = (hours - rhours) * MINUTES_IN_HOUR
    const rminutes = Math.round(minutesRest)

    if (rminutes > 0) {
      duration = `${rhours}h, ${rminutes}min`
    } else {
      duration = `${rhours}h`
    }
  }

  return duration
}

export function getFormattedTimeInMinutes(seconds) {
  if (!isNaN(seconds)) {
    // eslint-disable-next-line no-magic-numbers
    return Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2)
  }
}

export const secondsToTimeString = seconds => {
  const hours = Math.floor(seconds / TIME_DATE.SECONDS_IN_AN_HOUR)
  const minutes = Math.floor(
    (seconds % TIME_DATE.SECONDS_IN_AN_HOUR) / TIME_DATE.MINUTES_IN_AN_HOUR
  )
  const secs = Math.floor(seconds % TIME_DATE.SECONDS_IN_A_MINUTE)

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
}

export const timeStringToSeconds = value => {
  return value.split(':').reduce((acc, time) => TIME_DATE.SECONDS_IN_A_MINUTE * acc + +time, 0)
}

export const dateObjToDateOnlyString = date => {
  const yearString = String(date.getFullYear()).padStart(YEAR_STRING_MIN_LENGTH, '0')
  const monthString = String(date.getMonth() + 1).padStart(2, '0')
  const dayString = String(date.getDate()).padStart(2, '0')
  return `${yearString}-${monthString}-${dayString}`
}

export const getDateObjectFromDateOnlyAndTimeStrings = (dateOnlyString, timeString) => {
  const [yearString, monthString, dayString] = dateOnlyString.split('-')
  const [hourString, minuteString, secondString] = timeString.split(':')
  return new Date(
    Number(yearString),
    Number(monthString) - 1,
    Number(dayString),
    Number(hourString),
    Number(minuteString),
    Number(secondString)
  )
}
