export const getDimensions = ele => {
  const {height, width} = ele.getBoundingClientRect()
  const offsetTop = ele.offsetTop
  const offsetBottom = offsetTop + height

  return {
    height,
    width,
    offsetTop,
    offsetBottom
  }
}

export function getViewportDimensions() {
  let viewportWidth = undefined
  let viewportHeight = undefined

  if (process.browser && window.document) {
    viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || undefined)
    viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || undefined
    )
  }

  return {viewportWidth, viewportHeight}
}

/**
 *  Sets body overflow
 * @param {Boolean} visible Sets body overflow
 */
export function bodyOverflowVisibile(visible = true) {
  document.body.style.overflow = !visible ? 'hidden' : 'auto'
}
