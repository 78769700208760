import styled from 'styled-components'

export const P = styled.p`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '16px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '400')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '19px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '0 0 10px 0')};
`

export const H1 = styled.h1`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '40px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '48px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '0 0 10px 0')};
`

export const H2 = styled.h2`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '32px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '38px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '0 0 10px 0')};
`

export const H3 = styled.h3`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '28px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '33px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '0 0 10px 0')};
`

export const H4 = styled.h4`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '24px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '28px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '0 0 10px 0')};
`

export const H5 = styled.h5`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '20px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '24px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '0 0 10px 0')};
`

export const H6 = styled.h6`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '16px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '19px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '0 0 10px 0')};
`
