import axios from 'axios'
import cookie from 'js-cookie'

export const createApiInstance = baseUrl =>
  axios.create({
    baseURL: baseUrl
  })

export const apiCall = (call, onSuccess = () => {}, onError = () => {}) => {
  call()
    .then(data => {
      onSuccess(data)
    })
    .catch(error => {
      onError(
        (error.response && error.response.data && error.response.data.error) ||
          'ERRORS.ERROR_OCCURRED'
      )
    })
}

export const getHeader = (token, lang = 'en', additionalHeaders = {}) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token || (cookie && cookie.get('token')) || '',
      'x-cp-client': 'cp-web-client',
      'accept-language': 'en',
      ...additionalHeaders
    }
  }
}
