import copy from 'copy-to-clipboard'
import Noty from 'noty'

import {getStaticImageUrl} from 'assets/helpers'
import {getHost} from 'common/helpers/url'
import {DEFAULT_NOTIFICATION_DURATION} from 'consts.json'

export const DEFAULT_IMAGE = getHost() + getStaticImageUrl('cp_share_thumbnail.png')

/**
 * Get mobile operating system
 */
export const getMobileOperatingSystem = () => {
  if ((typeof __TEST__ != 'undefined' && __TEST__) || !(process.browser && window.document)) {
    return 'unknown'
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export const getIOSVersion = () => {
  if (getMobileOperatingSystem() === 'iOS') {
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
  }
  return [0]
}

/**
 * Copy to clipboard
 */
export function copyToClipboard(text) {
  copy(text)
}

/**
 * Notification helper
 */
export const notificate = (type, text, options = {}) => {
  return new Noty({
    layout: options.layout || 'bottomLeft',
    timeout: options.timeout || DEFAULT_NOTIFICATION_DURATION,
    killer: options.killer || true,
    text,
    type
  }).show()
}

export const notificateOnError = (text, options) => notificate('error', text, options)

export const shouldShowDownloadAppBanner = () => {
  // If OS is not detected as mobile
  if (getMobileOperatingSystem() === 'unknown') {
    return false
  }

  // If sessionStorage is not supported
  if (!window.sessionStorage) {
    return false
  }

  // If hideDownloadAppBanner key is in sessionStorage
  if ('hideDownloadAppBanner' in window.sessionStorage) {
    return false
  }

  return true
}

export const resolveReferralID = referralID => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    if (referralID) {
      window.sessionStorage.setItem('referralID', referralID)
    } else if (window.sessionStorage.referralID) {
      return window.sessionStorage.referralID
    }
  }
  return referralID
}

export const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } catch (error) {
      window.scrollTo(0, 0)
    }
  }
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export async function getBlobFromBase64(imageBase64) {
  const fetchResult = await fetch(imageBase64)
  return await fetchResult.blob()
}

export const scrollbarVisible = element => {
  if (!element) {
    return true
  }
  return element.scrollHeight > element.clientHeight
}
