/**
 * Check is obect empty
 *
 * @param {Object} obj To be tested
 * @returns {Boolean}  Returns processed value.
 */
export function isEmptyObject(obj) {
  return !obj ? false : Object.entries(obj).length === 0 && obj.constructor === Object
}

export function objectToQueryString(obj) {
  let str =
    '?' +
    Object.keys(obj)
      .reduce(function (a, k) {
        a.push(k + '=' + encodeURIComponent(obj[k]))
        return a
      }, [])
      .join('&')
  return str
}
