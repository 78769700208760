import {css} from 'styled-components'

const THEME_CONF = 'flexboxgrid'

export const BREAKPOINTS = {
  xxs: 0,
  xs: 390,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 2000
}

export const BASE_CONF = {
  gridSize: 12,
  gutterWidth: 32,
  outerMargin: 16,
  mediaQuery: 'only screen',
  container: {
    xxs: 0,
    xs: 358,
    sm: 544,
    md: 736,
    lg: 660,
    xl: 1168
  },
  breakpoints: {
    xxs: BREAKPOINTS.xxs,
    xs: BREAKPOINTS.xs,
    sm: BREAKPOINTS.sm,
    md: BREAKPOINTS.md,
    lg: BREAKPOINTS.lg,
    xl: BREAKPOINTS.xl,
    xxl: BREAKPOINTS.xxl
  },
  screens: {
    xxs: `(min-width: ${BREAKPOINTS.xxs}px)`,
    xs: `(min-width: ${BREAKPOINTS.xs}px)`,
    sm: `(min-width: ${BREAKPOINTS.sm}px)`,
    md: `(min-width: ${BREAKPOINTS.md}px)`,
    lg: `(min-width: ${BREAKPOINTS.lg}px)`,
    xl: `(min-width: ${BREAKPOINTS.xl}px)`,
    xxl: `(min-width: ${BREAKPOINTS.xxl}px)`
  }
}

const configCache = []
const makeCacheId = props => JSON.stringify((props.theme && props.theme[THEME_CONF]) || {})
const resolveConfig = props => {
  const themeConf = (props.theme && props.theme[THEME_CONF]) || {}

  const conf = {
    ...BASE_CONF,
    ...themeConf,
    container: {
      ...BASE_CONF.container,
      ...themeConf.container
    },
    breakpoints: {
      ...BASE_CONF.breakpoints,
      ...themeConf.breakpoints
    }
  }

  conf.media = Object.keys(conf.breakpoints).reduce((media, breakpoint) => {
    const breakpointWidth = conf.breakpoints[breakpoint]
    media[breakpoint] = makeMedia(
      [conf.mediaQuery, breakpoint !== 0 && `(min-width: ${breakpointWidth}px)`]
        .filter(Boolean)
        .join(' and ')
    )
    return media
  }, {})

  return conf
}

export const DIMENSION_NAMES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl']

export default function config(props) {
  const cacheId = makeCacheId(props)
  if (configCache[0] === cacheId) {
    return configCache[1]
  }

  const conf = resolveConfig(props)

  configCache[0] = cacheId
  configCache[1] = conf

  return conf
}

function makeMedia(media) {
  return (...args) => css`
    @media ${media} {
      ${css(...args)};
    }
  `
}
