const NextI18Next = require('next-i18next').default
const consts = require('./consts.json')

const otherLanguages = consts.SUPPORTED_LANGUAGES.filter(lang => lang !== consts.DEFAULT_LANGUAGE)

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: consts.DEFAULT_LANGUAGE,
  otherLanguages,
  fallbackLng: consts.DEFAULT_LANGUAGE,
  load: 'languageOnly',
  saveMissing: true,
  whitelist: consts.SUPPORTED_LANGUAGES,
  cleanCode: true,
  ignoreRoutes: ['/_next/', '/.well-known/', '/public/', '/static/', '/sitemap.xml', '/robots.txt']
})

NextI18NextInstance.i18n.languages = consts.SUPPORTED_LANGUAGES

module.exports = NextI18NextInstance
