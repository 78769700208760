import {BASE_CONF} from 'components/common/CPTheme'

export default {
  fontFamily: 'Nunito Sans, sans-serif',
  contentWidth: '1152px',
  colors: {
    blue: '#61b7cb',
    red: '#c82853',
    orange: '#db5811',
    green: '#659f17',
    yellow: '#faa929',
    black: '#000000',
    white: '#ffffff',
    dark2: '#253044',
    gray: '#ededed',
    primary1: '#61b7cb',
    primary2: '#c82853',
    primary3: '#db5811',
    primary4: '#659f17',
    primary5: '#faa929',
    primary1Opacity: '#e6f3f6',
    primary5Opacity: '#fef1dd',
    primary1Hover: '#4fa9be',
    primary2Hover: '#b02349',
    primary1Disabled: '#d9eef3',
    primary2Disabled: '#f6dce3',
    secondary1: '#f0f2f4',
    secondary1Hover: '#e0e2e4',
    secondary2: '#d2d9df',
    secondary3: '#b5bfca',
    secondary4: '#97a6b5',
    secondary5: '#798c9f',
    secondary6: '#607386',
    secondary7: '#4a5968',
    secondary8: '#2e363e',
    secondary9: '#20262d',
    secondary10: '#14181c'
  },
  mediaQuery: {
    xxs: `@media only screen and ${BASE_CONF.screens.xxs}`,
    xs: `@media only screen and ${BASE_CONF.screens.xs}`,
    sm: `@media only screen and ${BASE_CONF.screens.sm}`,
    md: `@media only screen and ${BASE_CONF.screens.md}`,
    lg: `@media only screen and ${BASE_CONF.screens.lg}`,
    xl: `@media only screen and ${BASE_CONF.screens.xl}`,
    xxl: `@media only screen and ${BASE_CONF.screens.xxl}`
  }
}
