import styled from 'styled-components'
import config, {DIMENSION_NAMES} from './config'

const offsetProps = DIMENSION_NAMES.map(d => d + 'Offset')

const oneHundred = 100

const Col = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 0 0 100%;
  padding-right: ${p => config(p).gutterWidth / 2}px;
  padding-left: ${p => config(p).gutterWidth / 2}px;
  ${p =>
    p.reverse &&
    `
    flex-direction: column-reverse;
  `}
  ${p =>
    Object.keys(p)
      .filter(k => ~DIMENSION_NAMES.indexOf(k))
      .sort((k1, k2) => DIMENSION_NAMES.indexOf(k1) - DIMENSION_NAMES.indexOf(k2))
      .map(
        k =>
          config(p).media[k]`${
            !isNaN(p[k])
              ? // Integer value
                `
        flex-basis: ${(oneHundred / config(p).gridSize) * p[k]}%;
        max-width: ${(oneHundred / config(p).gridSize) * p[k]}%;
        display: block;
      `
              : // Boolean
              p[k]
              ? // Auto-width
                `
          flex-grow: 1;
          flex-basis: 0;
          max-width: 100%;
          display: block;
        `
              : // Hide element
                'display: none;'
          }`
      )}
  ${p =>
    Object.keys(p)
      .filter(k => ~offsetProps.indexOf(k))
      .map(
        k =>
          config(p).media[k.replace(/Offset$/, '')]`
        margin-left: ${(oneHundred / config(p).gridSize) * p[k]}%;
      `
      )}
`

export default Col
