import React from 'react'

const AppContext = React.createContext({})
const AppProvider = AppContext.Provider
const AppConsumer = AppContext.Consumer

const CONTEXT_ID = 'app'

const stateData = {
  showSearch: false,
  searchTerm: '',
  showSideDrawer: false,
  purchaseDone: false,
  referralDone: false,
  isStickyMenuActive: false,
  showDownloadAppBanner: false,
  singnedupWithEmail: '',
  signedUpSuccessful: false,
  userAgent: undefined,
  showChatBot: true
}

export default {
  AppContext,
  AppProvider,
  AppConsumer,
  stateData,
  CONTEXT_ID
}
