/**
 * withTranslation
 * Higher-order component that wraps child component and enables CPAppContext to be used as props.
 * @flow
 */

import {Component} from 'react'
import CPAppContext from 'common/context/CPApp/CPAppContext'

const withAppContext = ComponentToExtend => {
  return class WrapperComponent extends Component {
    static async getInitialProps(ctx) {
      // Get component’s props
      let componentProps = {}
      if (ComponentToExtend.getInitialProps) {
        componentProps = await ComponentToExtend.getInitialProps(ctx)
      }

      return {
        ...componentProps
      }
    }

    render() {
      return (
        <CPAppContext.AppConsumer>
          {appContext => <ComponentToExtend {...this.props} appContext={appContext} />}
        </CPAppContext.AppConsumer>
      )
    }
  }
}

export default withAppContext
