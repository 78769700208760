/*
 * @flow
 *
 * Load configurations based on environment.
 */

let config = {}
const getConfig = require('next/config')

if (getConfig.default() || getConfig.publicRuntimeConfig) {
  config =
    process.env.NODE_ENV === 'test'
      ? getConfig.publicRuntimeConfig
      : getConfig.default().publicRuntimeConfig
} else {
  // FIXME Check if this is the best way of putting data for jest tests.
  // Test config
  config = {
    INTEGRITY_KEY: 'test-key',
    CAPTCHA_API_KEY: 'test-captcha',
    STRIPE_KEY: 'pk_test_6xRz09XNyrnPBuQBRbjEpIl500hH0JL2mr'
  }
}

export default config
