import {getAsync} from './cpBackendApi'
import {objectToQueryString} from 'common/helpers'
import {DATA} from '../tests/stubs/searchStubs'

export const getPlace = async (id, serviceName, slug, languageId) => {
  const response = await getAsync(
    `places/place/${id}?serviceName=${serviceName}&slag=${slug}&lang=${languageId}`
  )
  return response.data
}

export const getPlaceBundles = async (id, languageId) => {
  const response = await getAsync(`places/place/${id}/media-bundles?lang=${languageId}`)
  return response.data
}

export const getNearbyPlaces = async args => {
  const response = await getAsync(`places/nearest${objectToQueryString(args)}`)
  return response.data
}

export const getSearchPlaces = async args => {
  // TODO :: This will be replaced with new search endpoint
  // const response = await getAsync(`places/search${objectToQueryString(args)}`)
  const response = {
    data: DATA
  }
  return response.data
}

export const getPlaceThumbnails = async args => {
  const response = await getAsync(`places/photos?ids=${args}`)
  return response.data
}
