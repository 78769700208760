/**
 * Parse HTML and check whether plain content length matches limits
 *
 * @param {String} text
 * @param {Number} [minLength = 1]
 * @param {Number} [maxLength]
 *
 * @return {Boolean}
 */
export const checkIfValidPlainContentLength = (text, minLength = 1, maxLength) => {
  const contentLength = getPlainContentLengthOfHtmlString(text)

  if (typeof minLength === 'number' && contentLength < minLength) {
    return false
  }

  if (typeof maxLength === 'number' && contentLength > maxLength) {
    return false
  }

  return true
}

/**
 * Get plain content length of HTML string
 *
 * @param {String} str
 * @return {number}
 */
export const getPlainContentLengthOfHtmlString = str => {
  const parsedText = stripHtmlTags(str)
  return parsedText.length
}

/**
 * Strip all HTML tags from string.
 *
 * @param {String} str Text containing HTML tags.
 */
export const stripHtmlTags = str => {
  if (str === null || str === '' || str === undefined) return ''
  str = str.toString()
  return str
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/\s\s+/g, ' ')
    .replace(/&amp;/g, '&')
}

/**
 * Remove all HTML tags from string and format .
 *
 * @param {String} str Text containing HTML tags.
 */
export const removeHtmlTagsAndFormat = str => {
  if (str === null || str === '') return ''
  return str
    .replace(/&nbsp;/gi, ' ')
    .replace(/<\/p>/gi, '\n')
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/\s\s+/g, ' ')
}

/**
 * Prepare and filter all HTML tags.
 *
 * @param {String} content Passed HTML text.
 */
export const prepareHTMLContent = content => {
  return (
    content
      // remove [caption] from text
      .replace(/\[(.*?)]/g, '')
      // add new row for all non breaking space characters in order to all space after images
      .replace(/&nbsp;/g, '<br />')
      // Remove strong|em|a|! tags
      .replace(/<\/?(strong|em|a|!).*?>/g, '')
      // add p tag to text
      .replace(/^([^<])+/gm, '<p>$&</p>')
      // remove all inline classes
      .replace(/class=".*?"/g, '')
      // remove all inline styles
      .replace(/style=".*?"/g, '')
  )
}

/**
 * Parse HTML string and exclude all tags except allowed.
 *
 * @param {String} input HTML content string.
 * @param {Array} allowed Array of string as allowed tags.
 */
export function stripTags(input, allowed) {
  if (Object.prototype.toString.call(input) !== '[object String]') return input
  if (input === null || input === '') return ''

  input = input.replace(/&nbsp;/gi, ' ')

  // eslint-disable-next-line max-len
  allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('') // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
  input = input.replace(tags, function (first, second) {
    let res = first === '</p>' ? '\n' : ' '
    res += allowed.indexOf('<' + second.toLowerCase() + '>') > -1 ? first : ' '
    return res
  })

  return input
    .replace(/(<p>\s\s+<\/p>)+/g, '')
    .replace(/\s\s+/g, ' ')
    .trim()
}
