import React, {useState, useEffect} from 'react'
import cookie from 'js-cookie'
import Router from 'next/router'

export type Context = {
  user: Object
}

export const defaultContext: Context = {
  user: {isUserLogged: false}
}

export const CPUserContext = React.createContext(defaultContext)

export const CPUserProvider = props => {
  const [user, setUser] = useState(props.user)

  useEffect(() => {
    window.addEventListener('storage', onLoginChanged)
    return () => {
      window.removeEventListener('storage', onLoginChanged)
    }
  }, [])

  useEffect(() => {
    if (props.user !== user) {
      setUser(props.user)
    }
  }, [props.user])

  const onLoginChanged = event => {
    if (event.key === 'logout') {
      Router.reload()
    } else if (event.key === 'login') {
      Router.reload()
    }
  }

  const updateUser = (user, callback: () => {}) => {
    setUser(user, () => {
      cookie.set('user', JSON.stringify(user), {expires: 1})
      callback()
    })
  }

  return (
    <CPUserContext.Provider
      value={{
        user: {...user, updateUser}
      }}
    >
      {props.children}
    </CPUserContext.Provider>
  )
}

export default CPUserProvider
