import styled from 'styled-components'

export const Container = styled.div`
  font-size: ${props => (props.fontSize !== undefined ? props.fontSize : '16px')};
  font-family: ${props => (props.fontFamily !== undefined ? props.fontFamily : 'Nunito Sans')};
  font-weight: ${props => (props.fontWeight !== undefined ? props.fontWeight : '400')};
  line-height: ${props => (props.lineHeight !== undefined ? props.lineHeight : '19px')};
  color: ${props => (props.color !== undefined ? props.color : 'inherit')};
  margin: ${props => (props.margin !== undefined ? props.margin : '16px 0')};
  padding: ${props => (props.padding !== undefined ? props.padding : '0')};

  @media only screen and (min-width: 768px) {
    margin: ${props => (props.margin !== undefined ? props.margin : '32px 0')};
  }
`
