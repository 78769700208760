import {getMobileOperatingSystem} from 'common/helpers/general'
import {MIN_FIRST_NAME_LENGTH, MIN_LAST_NAME_LENGTH, MIN_PASSWORD_LENGTH} from 'consts.json'

export const isFirstNameValid = firstName => {
  return firstName && firstName.length >= MIN_FIRST_NAME_LENGTH
}

export const isLastNameValid = lastName => {
  return lastName && lastName.length >= MIN_LAST_NAME_LENGTH
}

export const isPasswordValid = password => {
  return password && password.length >= MIN_PASSWORD_LENGTH
}

// The official standard (RFC 2822) from email validation.
export const isEmailValid = (
  mail // eslint-disable-next-line
) => /^\w+([!#$%&.'*+\/=?^_`{|}~-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(mail)

export const isUrlValid = str =>
  // eslint-disable-next-line max-len
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
    str
  )

/**
 * Check is valid phone number
 *
 * @param {String} number.
 * @returns {Boolean} .
 */
export const checkPhone = number => new RegExp(/^[+]?([0-9-/]+)?$/).test(number)

/**
 * Validate file extensions
 */
export const validateFileExtensions = (fileName, allowedExtensions) => {
  return allowedExtensions.includes(fileName.split('.').pop().toLowerCase())
}

export const isNumeric = val => {
  return Number(parseFloat(val)) === val
}

export function isSafari(userAgent = undefined) {
  if (userAgent) {
    return /^((?!chrome|android).)*safari/i.test(userAgent)
  }

  return process.browser && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const isMobile = () => {
  return getMobileOperatingSystem() !== 'unknown'
}

export const hasGetUserMedia = () => {
  return (
    process.browser &&
    window.document &&
    navigator.mediaDevices &&
    navigator.mediaDevices.getUserMedia
  )
}
