/*
 * Shrink number of gps coord decimal points
 */
// eslint-disable-next-line no-magic-numbers
export function normalizeGPSCoordinate(coordinate, number = 5) {
  let coord = typeof coordinate === 'string' ? parseFloat(coordinate) : coordinate

  if (typeof coord !== 'number') {
    // eslint-disable-next-line no-console
    console.error('Coordinate is not an number')
    return coord
  }

  return coord.toFixed(number)
}
