import {i18n} from 'i18n.js'

import {DEFAULT_LANGUAGE} from 'consts.json'

const PROMO_VIDEO_URL_DEFAULT = 'https://vimeo.com/762600121'
const PROMO_VIDEO_URL_GERMAN = 'https://vimeo.com/751136490'

export const resolvePromoVideoUrl = () => {
  const currLang = resolveCurrentLanguage()
  return currLang === 'de' ? PROMO_VIDEO_URL_GERMAN : PROMO_VIDEO_URL_DEFAULT
}

/**
 *  Returns current language that is selected
 * @param req {Object} optional request object,
 * from which language could be extracted during server side rendering.
 * @returns {string}
 */
export function resolveCurrentLanguage(req = undefined) {
  if (i18n && 'language' in i18n && i18n.language) {
    return i18n.language.toString().substring(0, 2)
  }

  if (typeof window !== 'undefined' && window.localStorage.i18nextLng) {
    return window.localStorage.i18nextLng.toString().substring(0, 2)
  }

  if (req && 'language' in req && req.language) {
    return req.language.toString().substring(0, 2)
  }

  return 'en'
}

/**
 * Calls i18n.changeLanguage and replaces current url to proper one.
 * @param {string} newLng code for new language (e.g. 'en')
 */
export function changeLanguageHelper(newLng) {
  i18n.changeLanguage(newLng, () => {
    localStorage.setItem('i18nextLng', newLng)

    //Commented out because of CPP-3667
    //URL should stay the same after language change

    // const prevLng = i18n.language

    // if (newLng === DEFAULT_LANGUAGE) {
    //   window.location = window.location
    //     .toString()
    //     .replace(`/${prevLng}/`, '/')
    //     .replace(new RegExp(`/${prevLng}$`), '')
    // } else {
    //   window.location = window.location
    //     .toString()
    //     .replace(`/${prevLng}/`, `/${newLng}/`)
    //     .replace(new RegExp(`/${prevLng}$`), `/${newLng}`)
    // }
  })
}

export const LANGUAGES = [
  {
    value: 'id',
    label: 'Bahasa Indonesia'
  },
  {
    value: 'de',
    label: 'Deutsch'
  },
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'hr',
    label: 'Hrvatski'
  },
  {
    value: 'it',
    label: 'Italiano'
  },
  {
    value: 'sl',
    label: 'Slovenščina'
  },
  {
    value: 'sh',
    label: 'Srpski'
  },
  {
    value: 'sr',
    label: 'Српски'
  },
  {
    value: 'zh',
    label: '中文'
  }
]
