import {resolveCurrentLanguage} from './language'

import {getHost} from './index'

function getFormattedPayload(
  shoppingCart,
  referralID = null,
  paymentGateway = '',
  orderTaxInfo = null
) {
  const {order} = shoppingCart.cart

  const payload = shoppingCart.getFormatedOrder(order)
  const lang = resolveCurrentLanguage()

  Object.assign(payload, {paymentGateway: paymentGateway})

  if (referralID) {
    Object.assign(payload, {referralID: referralID})
  }

  Object.assign(payload, {
    redirectOK: `${getHost()}/${lang}/checkout`,
    redirectError: `${getHost()}/${lang}/checkout`
  })

  if (orderTaxInfo) {
    Object.assign(payload, {
      taxRefundCity: orderTaxInfo.city,
      taxRefundCountry: orderTaxInfo.country,
      taxRefundStreetAddress: orderTaxInfo.streetAddress,
      taxRefundZip: orderTaxInfo.zipCode,
      birthdayTimestamp: orderTaxInfo.dateOfBirth
    })
  }

  return payload
}

export function getFormattedPayPalPayload(payload) {
  return getFormattedPayload(
    payload.shoppingCart,
    payload.referralID,
    'PAYPAL',
    payload.orderTaxInfo
  )
}

export function getFormattedCreditCardPayload(payload) {
  return getFormattedPayload(
    payload.shoppingCart,
    payload.referralID,
    'STRIPE',
    payload.orderTaxInfo
  )
}

export function mapFundingSourceToCard({id, funding, lastFourDigits}) {
  return {
    id,
    type: funding,
    number: lastFourDigits
  }
}
