import config from 'config'
import {
  APP_STORE_URL,
  HYPERURL_URL,
  PLAY_STORE_URL,
  PRIVACY_POLICY_URL,
  PRIVACY_POLICY_URL_DE,
  TERMS_AND_PRIVACY_URL,
  TERMS_AND_PRIVACY_URL_DE,
  TERMS_OF_USE_LINK_FOR_CONTENT_PROVIDERS
} from 'consts.json'

export function resolveDeepLinkUrl({page, pageId, code}) {
  if (__TEST__ || !(process.browser && window.document)) {
    return ''
  }

  let isOpera =
    // eslint-disable-next-line
    (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
  let isFirefox = typeof InstallTrigger !== 'undefined'
  // eslint-disable-next-line
  const operaFirefoxLink =
    'https://culturalplaces.page.link/?link=https://play.google.com/store/apps/details?id%3Dcom.cpapp' +
    (page ? '%26page%3D' + page : '') +
    (pageId ? '%26pageId%3D' + pageId : '') +
    (code ? '%26code%3D' + code : '') +
    '&apn=com.cpapp&isi=1234962719&ibi=com.oroundo.CPApp'
  const chromeLink =
    'https://culturalplaces.page.link/page?' +
    (page ? 'page=' + page : '') +
    (pageId ? '&pageId=' + pageId : '') +
    (code ? '&code=' + code : '')

  return isOpera || isFirefox ? operaFirefoxLink : chromeLink
}

export function resolveCurrentDeepLinkUrl(router) {
  const {query, route} = router || {query: '', route: '/'}
  const pageId = query.id || null
  const URL = resolveDeepLinkUrl({
    page: route.replace('/', ''),
    pageId
  })
  return URL
}

export function isRouteActive(router, i18n, route) {
  const language = i18n && 'language' in i18n ? `/${i18n.language}` : ''
  const path =
    router && 'asPath' in router ? router.asPath.replace(language, '').replace(/\/$/, '') : ''
  const currentRoute = route.replace(/\/$/, '')

  if (currentRoute.length === 0) {
    if (path.length === 0) {
      return true
    } else {
      return false
    }
  } else {
    if (path === `${currentRoute}`) {
      return true
    } else if (path.startsWith(`${currentRoute}`)) {
      return true
    } else {
      return false
    }
  }
}

export function resolveLinkUrl(title, lang) {
  const termsAndConditions = 'TERMS_AND_CONDITIONS'

  if (title === termsAndConditions) {
    return lang === 'de' ? TERMS_AND_PRIVACY_URL_DE : TERMS_AND_PRIVACY_URL
  }
  return lang === 'de' ? PRIVACY_POLICY_URL_DE : PRIVACY_POLICY_URL
}

export const resolveTermsOfUseLinkForContentProviders = languageId => {
  if (languageId === 'de') {
    // TODO: Change to url of document in German language when ready.
    return TERMS_OF_USE_LINK_FOR_CONTENT_PROVIDERS
  }
  return TERMS_OF_USE_LINK_FOR_CONTENT_PROVIDERS
}

export function getAppStoreUrl() {
  if (__TEST__ || !(process.browser && window.document)) {
    return HYPERURL_URL
  }

  // eslint-disable-next-line no-undef
  // eslint-disable-next-line vars-on-top
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return PLAY_STORE_URL
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return APP_STORE_URL
  }

  return HYPERURL_URL
}

/**
 * Resolves sharing url for places and stories
 * @param page
 * @param urlParam
 */
export function resolveShareUrl(page = '', urlParam = '') {
  if (page === 'story') {
    return config.BLOG_ROOT_URL + urlParam
  }
  return getHost() + urlParam
}

export function getPlaceURL({seoURL, citySlug, countrySlug, id}) {
  if (seoURL && citySlug && countrySlug) {
    return '/place/' + countrySlug + '/' + citySlug + '/' + seoURL
  } else return '/place?id=' + id
}

export const extractHostname = url => {
  // eslint-disable-next-line init-declarations
  let hostname

  //find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }

  //find & remove www
  if (hostname.indexOf('www.') > -1) {
    hostname = hostname.split('www.')[1]
  }

  //find & remove port number
  hostname = hostname.split(':')[0]

  //find & remove "?"
  hostname = hostname.split('?')[0]

  return hostname
}

/**
 * Get current hostname
 */
export function getHost() {
  return config.SERVER_URL
}

export const ensureValidUrl = url =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`

export const getCurrentURLQuery = () => {
  let params = window.location.search.replace('?', '')
  let searchParams = new URLSearchParams(params)
  return Object.fromEntries(searchParams)
}

export const prepareMailHref = ({toEmail, toEmails = [], bccEmails = []}) => {
  let mailHref = 'mailto:'
  // Add "to" recipient(s).
  if (typeof toEmail === 'string') {
    mailHref += toEmail
  } else if (toEmails.length > 0) {
    // Multiple to recipients in mailto link are not well supported throughout user agents.
    mailHref += toEmails.join(';')
  }
  // Add "bcc" recipients.
  if (bccEmails.length > 0) {
    mailHref += `?bcc=${bccEmails.join(',')}`
  }
  return mailHref
}
