/* eslint-disable init-declarations */
import {DEFAULT_IMG_FORMAT, FALLBACK_IMG_FORMAT} from 'consts.json'
import {isSafari} from 'common/helpers/validation'
import config from 'config'
import memoizeOne from 'memoize-one'
import {getPlaceThumbnails} from 'services/placeService'

export function getTourImagesCollection(tour) {
  const imagesCollection = []
  tour.objects.forEach(object => {
    object.images.forEach(image => {
      imagesCollection.push({path: image.path, credits: image.credits, title: object.title})
    })
  })
  return imagesCollection
}

export function resolveStaticImagePath(path, userAgent = undefined) {
  if (path.includes(`.${DEFAULT_IMG_FORMAT}`) && isSafari(userAgent)) {
    return path.replace(`.${DEFAULT_IMG_FORMAT}`, `.${FALLBACK_IMG_FORMAT}`)
  }

  return path
}

/**
 * Process thumbnail and return processed data.
 *
 * @param {String} thumbnail Passed thumbnail.
 * @returns {String}  Returns processed value.
 */
export const resolveThumbnail = memoizeOne(thumbnail => {
  return thumbnail
    ? thumbnail.startsWith('http') || thumbnail.startsWith('/')
      ? thumbnail
      : `${config && config.CDN_BASE}/${thumbnail}`
    : ''
})

/**
 * Resolve small size image path
 *
 * @param {String} thumbnail Passed thumbnail
 * @returns {String}  Returns processed value
 */
export const resolveSmallImage = memoizeOne(thumbnail => {
  const fullImagePath = resolveThumbnail(thumbnail)

  if (fullImagePath.includes('/small/')) {
    return fullImagePath
  }

  // Append size folder name before last slash
  const index = fullImagePath.lastIndexOf('/')
  const replacedPath =
    fullImagePath.substr(0, index).replace('/private/', '/public/') +
    '/small' +
    fullImagePath.substr(index)

  // Strip query string
  return replacedPath.split('?')[0]
})

/**
 * Resolve medium size image path
 *
 * @param {String} thumbnail Passed thumbnail
 * @returns {String}  Returns processed value
 */
export const resolveMediumImage = memoizeOne(thumbnail => {
  const fullImagePath = resolveThumbnail(thumbnail)

  if (fullImagePath.includes('/medium/')) {
    return fullImagePath
  }

  // Append size folder name before last slash
  const index = fullImagePath.lastIndexOf('/')
  const replacedPath =
    fullImagePath.substr(0, index).replace('/private/', '/public/') +
    '/medium' +
    fullImagePath.substr(index)

  // Strip query string
  return replacedPath.split('?')[0]
})

/**
 * Merges thumbnails with existing places.
 *
 * @param {Array<Object>} places list of places
 * @param {Object{places: Array<Object>}} thumbnailsResponse response from server, containing places with thumbnails
 * @returns {Array<Object>} new array containing new object (objects that already had thumnails are left intact)
 */
export function mergeThumbnailsToPlaces(places, thumbnailsResponse) {
  const idThumbnailMap = thumbnailsResponse.reduce(
    (acc, item) => ((acc[item.id] = item.thumbnail), acc),
    {}
  )
  const placesWithThumbnails = places.map(place =>
    place.thumbnail ? place : {...place, thumbnail: idThumbnailMap[place.id]}
  )
  return placesWithThumbnails
}

export const createIdsQueryParamForThumbs = places => {
  return places
    .filter(item => item.thumbnail === '')
    .map(item => item.id)
    .join(',')
}

const checkIfPlaceArraysMatch = (placesArr1, placesArr2) => {
  if (!placesArr1 || !placesArr2 || placesArr1.length !== placesArr2.length) {
    return false
  }

  placesArr1.forEach((place1, index) => {
    const place2 = placesArr2[index]

    if (place1 === null && place2 !== null) {
      return false
    }

    if (place1 !== null && place2 === null) {
      return false
    }

    if (place1 && place2 && place1.id !== place2.id) {
      return false
    }
  })

  return true
}

/**
 * Returns memoized function that will fetch thumbnails for provided places.
 */

export const fetchThumbnailsMemoizer = () => {
  let prevPlaces
  let prevWithThumbs

  return async places => {
    if (prevPlaces && checkIfPlaceArraysMatch(prevPlaces, places)) {
      return prevWithThumbs
    }

    prevPlaces = places

    const ids = createIdsQueryParamForThumbs(places)

    if (ids.length === 0) {
      prevWithThumbs = places
      return places
    }

    const thumbnailData = await getPlaceThumbnails(ids)
    const withThumbs = mergeThumbnailsToPlaces(places, thumbnailData.places)
    prevWithThumbs = withThumbs
    return withThumbs
  }
}

/**
 * Returns base64 from blob
 */

export const blobToBase64 = blob => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}
